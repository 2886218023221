<template>
  <div id="app">
    <div class="app-topbar">
        <img src="../src/assets/QQ截图20220824141512.png" alt="公司名称" class="app-topbar-icon">
        <div class="app-topbar-navbar">
            <div v-for="(item, index) in navbarData" :key="index"
                class="app-topbar-navbar-type"
                @click="navbarClick(index,item.path)"
                :class="{navbarActive:navbarIndex == index}">
                {{item.firstLevelTitle}}
                <div class="app-topbar-navbar-type-subtitleBox"
                    v-show="item.secondaryTitle.length != 0 && item.secondaryTitleSign == true">
                  <div v-for="(it, itindex) in item.secondaryTitle"
                    :key="itindex"
                    class="app-topbar-navbar-type-subtitleBox-type"
                    @click="secondaryTitleClick(it.path)">
                    {{it.title}}
                </div>
                </div>
            </div>
        </div>
    </div>


    <router-view/>


    <div class="app-statement">
      <div>
        <div class="app-statement-p1">本网站设计及数据均受版权保护，任何公司及个人不得以任何方式复制，违者将依法追究责任，特此声明。</div>
        <div class="app-statement-p2">友情链接 广告合作 联系我们 关于我们 @福州比特元科技有限公司 
          <a href="https://beian.miit.gov.cn/" class="app-statement-a">闽ICP备2021002781号-1</a>
        </div>
      </div>
    </div>

    <div class="popup" v-show="this.scrollTop_percentage > 0.14">
      <img src="../src/assets/img/popup/popup1.png" alt="" class="popup-img0">
      <div class="popup-divcs">
        <img src="../src/assets/img/popup/popup6.png" alt="" class="popup-divcs-img2">
      </div>
      <img src="../src/assets/img/popup/popup3.png" alt="" class="popup-img3" @click="backtop">
    </div>


  </div>
</template>


<script>
export default {
 name: 'App',
   data() {
      return {

        navbarData: [
          {
            'firstLevelTitle': '简介',
            'path': '/home',
            'secondaryTitle': [],
            'secondaryTitleSign': false,
          },
          {
            'firstLevelTitle': '我们的产品',
            'path': 'aaa',
            'secondaryTitle': [
              {
                'title': '树课智慧教室',
                'path': '/classroom'
              },
              {
                'title': 'AI科普智慧套件',
                'path': 'aaa'
              },
              {
                'title': '虚拟现实',
                'path': 'aaa'
              },
              {
                'title': '树课教育平台',
                'path': 'aaa'
              },
            ],
            'secondaryTitleSign': false,
          },
          {
            'firstLevelTitle': '新闻动态',
            'path': 'aaa',
            'secondaryTitle': [],
            'secondaryTitleSign': false,
          },
          {
            'firstLevelTitle': '关于我们',
            'path': 'aaa',
            'secondaryTitle': [],
            'secondaryTitleSign': false,
          },
          {
            'firstLevelTitle': '加入我们',
            'path': 'aaa',
            'secondaryTitle': [],
            'secondaryTitleSign': false,
          },
          {
            'firstLevelTitle': '登录树课',
            'path': 'aaa',
            'secondaryTitle': [],
            'secondaryTitleSign': false,
          },
        ],
        navbarIndex: 0,


        scrollTop_percentage: 0,
        Index: 0,
        // screen_width: 0,
        product_list: [
          // "树课智慧教室","AI科普套件","虚拟现实","树课教育平台",
          {'name': '/classroom', 'navitem': '树课智慧教室'},
          {'name': '/test', 'navitem': 'AI科普套件'},
          {'name': '/home', 'navitem': '虚拟现实'},
          {'name': '/home', 'navitem': '树课教育平台'},
        ],
      }
   },
   mounted() {
       window.addEventListener("scroll",this.showbtn,true);
      //  this.screen_width = document.body.clientWidth
  },
  methods: {
    navbarClick(index, path) {
      if(this.navbarIndex !== index) {
        this.navbarData.map((x)=> {
          return x.secondaryTitleSign = false
        })
      }
      this.navbarIndex = index
      this.navbarData[index].secondaryTitleSign = !this.navbarData[index].secondaryTitleSign
      if(path != 'aaa'){
        this.$router.push(path)
      }
    },
    secondaryTitleClick(path) {
      // console.log('path:',path)
      this.$router.push(path)
      // console.log('qqqq')
    },





    // command(path) {
    //   // console.log(path);
    //   this.$router.push(path)
    // },
    // jjClick() {
    //     this.$router.push('/home')
    //     // console.log('4444')
    //   },
    // 显示回到顶部按钮
       showbtn(){
          let that = this;
          let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          that.scrollTop = scrollTop
          let scrollTop_percentage = that.scrollTop/document.body.scrollHeight
          that.scrollTop_percentage = scrollTop_percentage
          // console.log(document.body.clientWidth)
       },
      /**
        * 回到顶部功能实现过程：
        * 1. 获取页面当前距离顶部的滚动距离（虽然IE不常用了，但还是需要考虑一下兼容性的）
        * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
        * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
        * 4. 最后记得在移动到顶部时，清除定时器
      */
      backtop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5); 
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
      }

  }
}
</script>


<style lang="scss" scoped>
@import "../src/assets/css/main.css";
.app-topbar {
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  padding: 1.2vw 12.5vw 1.2vw 12.5vw;
  &-icon{
    display: inline-block;
    width: 10vw;
    height: 2vw;
    margin-left: 3.4vw;
  }
  &-navbar {
    // border: 1px solid red;
    display: flex;
    margin-right: 6.25vw;
    color: #fff;
    &-type {
      // border: 1px solid blue;
      font-size: 0.8vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin-left: 2vw;
      position: relative;
      &-subtitleBox {
        position: absolute;
        top: 2.5vw;
        z-index: 99;
        width: 7.3vw;
        font-size: 2.5vw;
        padding: 0.4vw 0vw 0.8vw 0.8vw;
        // border: 1px solid green;
        color: #fff;
        background: rgba(3,8,22,0.8);
        animation: move 0.5s;
        animation-fill-mode: forwards;
        // opacity: 0.5;
        &-type {
          margin-top: 0.5rem;
          font-size: 0.7vw;
        }
        &-type:hover {
          color: #2680EB;
        }
      }
      
    }
  }
}
@keyframes move {
  0%{
    top: 0vw;
  }
  100%{
    top: 3vw;
  }
}
.navbarActive {
  color: #2680EB;
  border-bottom: 0.1875rem solid #2680EB;
}
.app-statement {
  width: 100vw;
  padding: 3.6vw 0vw 3.6vw 0vw;
  text-align: center;
  // border: 1px solid pink;
  background-color: #002A67;
  color: #fff;
  font-size: 0.7vw;
  &-p1 {
    margin-bottom: 1.5rem;
  }
  &-a {
    color: #fff;
    text-decoration: none; 
  }
}

.popup {
  position: fixed;
  top: 13vw;
  right: 5vw;
  width: 3vw;
  // border: 1px solid red;
  &-img0 {
    width: 3vw;
    height: 7.7vw;
    // border: 1px solid blue;
  }
  &-divcs {
    // border: 1px solid rgb(0, 255, 8);
    position: relative;
    &-img2 {
      width: 2.2vw;
      height: 12vw;
      margin-left: 0.4vw;
    }
  }
  &-img3 {
    width: 3vw;
    height: 3vw;
    // border: 1px solid blue;
  }
}


@media only screen and (max-width: 1080px) {
  .app-topbar{ 
    padding: 1.5rem 0vw 1.5rem 0vw;
  }
}
@media only screen and (max-width: 530px) {
  .app-topbar {
    display: block;
    &-icon {
      width: 26vw;
      height: 6vw;
      
    }
    &-navbar {
      margin-top: 4vw;
      margin-left: 14vw;
      &-type-subtitleBox {
        width: 20vw;
        // border: 1px solid red;
        margin-top: 5vw;
      }
    }
  }
  .popup {
    top: 65%;
    right: 5%;
    width: 7vw;
    // border: 1px solid red;
    &-img0 {
      width: 7vw;
      height: 18vw;
      // border: 1px solid blue;
    }
    &-divcs {
      // border: 1px solid rgb(0, 255, 8);
      position: relative;
      &-img2 {
        width: 7vw;
        height: 20vw;
      }
      &-img1 {
        position: absolute;
        width: 5vw;
        height: 5vw;
        // margin-left: 0.3vw;
        top: 0vw;
        left: 0.8vw;
      }
    }
    &-img3 {
      width: 7vw;
      height: 7vw;
      // border: 1px solid blue;
    }
  }
  .app-statement {
    padding: 3.6vw 10vw 3.6vw 10vw;
    width: 80vw;
  }
}
</style>
